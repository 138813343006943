import React, { useContext } from "react";
import NavMenu from "../../components/nav-menu/NavMenu";
import Helmet from "react-helmet";

import Footer from "../../components/main-page/sections/footer/Footer";
import GroupLegal from "../../components/group-legal/GroupLegal";
import styles from "./index.module.scss";
import { I18nextProvider } from "react-i18next";
import i18n from "../../i18n/i18n";
import DomainContext from "../../context/DomainContext";
import SEOComponent from "../../components/Shared/Seo";

const SiteMapPage = () => {
  const domain = useContext(DomainContext);

  return (
    <I18nextProvider i18n={i18n} defaultNS={"default"}>
      <Helmet>
        <meta content="sitemap" property="og:title" />
      </Helmet>
      <SEOComponent page="sitemap" />
      <NavMenu />
      <div className={styles.breadcrumb}>
        <a href="/">Home</a>
        <span className={styles.separator}>/</span>
        <span> Sitemap</span>
      </div>
      <div className={styles.siteMap}>
        <div className={styles.wContainer}>
          <h1 className={styles.heading}>Sitemap</h1>
          {domain !== "ch" ? (
            <>
              <div className={`${styles.linkContainer} ${styles.visiblePhone}`}>
                <div className={styles.linkGroup}>
                  <h3 className={styles.subHeading}>Unternehmen</h3>
                  <a href="/">Homepage </a>
                  <a href="/faq/ueber-uns/">Über uns </a>
                  <a href="/faq/support">So funktioniert's </a>
                  <a href="/faq/karriere/">Karriere </a>
                  <a href="/faq/presse/">Presse </a>
                  <a href="/blog/">Blog </a>
                  <a href="/faq/nachhaltigkeit/">Nachhaltigkeit </a>
                  <a href="/standorte/">Standorte </a> 
                </div>
                <div className={styles.linkGroup}>
                  <h3 className={styles.subHeading}>Verkaufen</h3>
                  <a href="/altmetall-kaufen/">Altmetallpreis anfragen </a>
                  <a href="/altmetall-ankauf/">Kleine Mengen verkaufen</a>
                </div>
                <div className={styles.linkGroup}>
                  <h3 className={styles.subHeading}>Kaufen</h3>
                  <a href="/altmetall-kaufen/">Altmetall kaufen </a>
                  <a href="/altmetall-kaufen/partner-werden/">
                    Schrott24 Partner werden
                  </a>
                  <a href="/altmetall-kaufen/partner/partnerwebshop/">
                    Ihr Webshop
                  </a>
                </div>
                <div className={styles.linkGroup}>
                  <h3 className={styles.subHeading}>Altmetall verkaufen</h3>
                  <a href="/altmetall-ankauf/">Altmetall Ankauf </a>
                  <a href="/altmetall-ankauf/aluminium/">Aluminium </a>
                  <a href="/altmetall-ankauf/blei/">Blei </a>
                  <a href="/altmetall-ankauf/edelstahl/">Edelstahl </a>
                  <a href="/altmetall-ankauf/eisen-und-stahl/">
                    Eisen und Stahl
                  </a>
                  <a href="/altmetall-ankauf/elektronik/">Elektroschrott </a>
                  <a href="/altmetall-ankauf/hartmetall-hss/">
                    Hartmetall und HSS
                  </a>
                  <a href="/altmetall-ankauf/kupfer-kabel/">Kabel </a>
                  <a href="/altmetall-ankauf/kupfer/">Kupfer </a>
                  <a href="/altmetall-ankauf/messing/">Messing </a>
                  <a href="/altmetall-ankauf/legierungen-cu-ni/">
                    Rotguss/Legierunge
                  </a>
                  <a href="/altmetall-ankauf/zink/">Zink </a>
                  <a href="/altmetall-ankauf/zinn/">Zinn</a>
                </div>
                <div className={styles.linkGroup}>
                  <h3 className={styles.subHeading}>Services</h3>
                  <a href="/altmetall-ankauf/demontage/">
                    Industrierückbau &amp; Demontagen
                  </a>
                  <a href="/altmetall-ankauf/kraftwerkdemontage/">
                    Kraftwerksrückbau
                  </a>
                  <a href="/altmetall-ankauf/windkraftwerkdemontage/">
                    Demontage von Windkraftanlagen
                  </a>
                  <a href="/altmetall-kaufen/partner-werden/">
                    Partner Webshop
                  </a>
                </div>
                <div className={styles.linkGroup}>
                  <h3 className={styles.subHeading}>Kunden</h3>
                  <a href="/pro">Industrie </a>
                  <a href="/altmetall-ankauf">Gewerbe/Handwerk </a>
                  <a href="/altmetall-kaufen">
                    Endverbraucher (Schmelzen, Hütten, etc.)
                  </a>
                  <a href="/altmetall-kaufen/partner-werden">
                    Altmetallhändler
                  </a>
                  <a href="/altmetall-ankauf">Privat </a> 
                </div>
                <div className={styles.linkGroup}>
                  <h3 className={styles.subHeading}>Newsletter</h3>
                  <a href="/faq/preisalarm/">Preisalarm </a>
                  <a href="/faq/marktupdate/">Marktupdate</a>
                </div>
                <div className={styles.linkGroup}>
                  <h3 className={styles.subHeading}>Impressum</h3>
                  <a href="/faq/impressum/">Impressium </a>
                  <a href="/faq/disclaimer/">Disclaimer </a>
                  <a href="/faq/agb/">AGB</a>
                </div>
              </div>
              <div className={`${styles.linkContainer} ${styles.visiblePhone}`}>
                <div className={styles.linkColumn}>
                  <div className={styles.linkGroup}>
                    <h3 className={styles.subHeading}>Schrottpreise</h3>
                    <a href="/schrottpreise/">Schrottpreise </a>
                    <a href="/hartmetallpreis/">Hartmetallpreis </a>
                    <a href="/edelstahlpreis/">Edelstahlpreis </a>
                    <a href="/zinkpreis/">Zinkpreis</a>
                    <a href="/messingpreise/">Messingpreis</a>
                    <a href="/kabelpreise/">Kabelpreis</a>
                    <a href="/aluminiumpreis/">Aluminiumpreis</a>
                    <a href="/elektroschrottpreise/">E-Schrottpreis</a>
                    <a href="/bleipreise/">Bleipreis</a>
                    <a href="/legierungenpreis/">Legierungenpreis</a>
                    <a href="/zinnpreis/">Zinnpreis</a>
                    <a href="/kupferpreis/">Kupferpreis</a>
                    <a href="/eisenpreise/">Eisenpreis</a>
                    <a href="/stahlpreise/">Stahlpreis</a>
                    <a href="/batteriepreise/">Batteriepreis</a>
                  </div>
                  <div className={styles.linkGroup}>
                    <h3 className={styles.subHeading}>Standorte Österreich</h3>
                    <a href="/standorte/schrottplatz-dornbirn/">
                      Raum Dornbirn
                    </a>
                    <a href="/standorte/schrottplatz-graz/">Raum Graz </a>
                    <a href="/standorte/schrottplatz-kaernten/">
                      Raum Klagenfurt/Althofen
                    </a>
                    <a href="/standorte/schrottplatz-linz/">Raum Linz </a>
                    <a href="/standorte/schrottplatz-salzburg/">
                      Raum Salzburg Stadt
                    </a>
                    <a href="/standorte/schrottplatz-wien/">Raum Wien</a>
                  </div>
                </div>
                <div className={styles.linkColumn}>
                  <div className={styles.linkGroup}>
                    <h3 className={styles.subHeading}>Standorte Deutschland</h3>
                    <a href="/standorte/schrottplatz-bayern/">Bayern </a>
                    <a href="/standorte/schrottplatz-donauwoerth/">
                      Donauwörth
                    </a>
                    <a href="/standorte/schrottplatz-eiselfing/">Eiselfing </a>
                    <a href="/standorte/schrottplatz-kiefersfelden/">
                      Kiefersfelden
                    </a>
                    <a href="/standorte/schrottplatz-memmingen/">Memmingen </a>
                    <a href="/standorte/schrottplatz-muenchen/">München </a>
                    <a href="/standorte/schrottplatz-nuernberg/">Nürnberg </a>
                    <a href="/standorte/schrottplatz-regensburg/">Regensburg</a>
                    <a href="/schrottplatz-baden-wuerttemberg/">
                      Baden-Württemberg
                    </a>
                    <a href="/standorte/schrottplatz-karlsruhe/">Karlsruhe </a>
                    <a href="/standorte/schrottplatz-mannheim/">Mannheim </a>
                    <a href="/schrottplatz-stuttgart/">Stuttgart </a>
                    <a href="/standorte/schrottplatz-neu-ulm/">Ulm </a>
                    <a href="/schrottplatz-berlin/">Berlin </a>
                    <a href="/schrottplatz-bremen/">Bremen </a>
                    <a href="/schrottplatz-hamburg-online-altmetall-verkaufen/">
                      Hamburg
                    </a>
                    <a href="/schrottplatz-hessen/">Hessen </a>
                    <a href="/schrottplatz-frankfurt/">Frankfurt am Main </a>
                    <a href="/schrottplatz-mecklenburg-vorpommern/">
                      Mecklenburg-Vorpommern
                    </a>
                    <a href="/standorte/schrottplatz-friedland/">Friedland </a>
                    <a href="/standorte/schrottplatz-brandenburg/">
                      Neubrandenburg
                    </a>
                    <a href="/standorte/schrottplatz-rostock/">Rostock </a>
                    <a href="/standorte/schrottplatz-schwerin/">Schwerin </a>
                    <a href="/standorte/schrottplatz-wismar/">Wismar </a>
                    <a href="/schrottplatz-niedersachsen/">Niedersachsen </a>
                    <a href="/standorte/schrottplatz-braunschweig/">
                      Braunschweig
                    </a>
                    <a href="/schrottplatz-hannover/">Hannover </a>
                    <a href="/standorte/schrottplatz-kettenkamp/">Kettenkamp</a>
                    <a href="/standorte/schrottplatz-osnabrueck/">Osnabrück </a>
                    <a href="/schrottplatz-nordrhein-westfalen/">
                      Nordrhein-Westfalen
                    </a>
                    <a href="/standorte/schrottplatz-hagen/">Hagen </a>
                    <a href="/standorte/schrottplatz-hamm/">Hamm </a>
                    <a href="/standorte/schrottplatz-luenen/">Lünen </a>
                    <a href="/standorte/schrottplatz-guetersloh/">Gütersloh </a>
                    <a href="/schrottplatz-saarland/">Saarland </a>
                    <a href="/schrottplatz-homburg/">Homburg </a>
                    <a href="/schrottplatz-sachsen/">Sachsen </a>
                    <a href="/schrottplatz-chemnitz/">Chemnitz </a>
                    <a href="/schrottplatz-dresden/">Dresden </a>
                    <a href="/schrottplatz-leipzig/">Leipzig </a>
                    <a href="/schrottplatz-sachsen-anhalt/">Sachsen-Anhalt </a>
                    <a href="/standorte/schrottplatz-halle/">Halle </a>
                    <a href="/schrottplatz-schleswig-holstein/">
                      Schleswig-Holstein
                    </a>
                  </div>
                </div>
              </div>
              <div
                className={`${styles.linkContainer} ${styles.withBottomBorder} ${styles.visibleDesktopTablet}`}
              >
                <div className={styles.linkColumn}>
                  <div className={styles.linkGroup}>
                    <h3 className={styles.subHeading}>Unternehmen</h3>
                    <a href="/">Homepage </a>
                    <a href="/faq/ueber-uns/">Über uns </a>
                    <a href="/faq/support">So funktioniert's </a>
                    <a href="/faq/karriere/">Karriere </a>
                    <a href="/faq/presse/">Presse </a>
                    <a href="/blog/">Blog </a>
                    <a href="/faq/nachhaltigkeit/">Nachhaltigkeit </a>
                    <a href="/standorte/">Standorte </a> 
                  </div>
                  <div className={styles.linkGroup}>
                    <h3 className={styles.subHeading}>Services</h3>
                    <a href="/altmetall-ankauf/demontage/">
                      Industrierückbau &amp; Demontagen
                    </a>
                    <a href="/altmetall-ankauf/kraftwerkdemontage/">
                      Kraftwerksrückbau
                    </a>
                    <a href="/altmetall-ankauf/windkraftwerkdemontage/">
                      Demontage von Windkraftanlagen
                    </a>
                    <a href="/altmetall-kaufen/partner-werden/">
                      Partner Webshop
                    </a>
                  </div>
                  <div className={styles.linkGroup}>
                    <h3 className={styles.subHeading}>Newsletter</h3>
                    <a href="/faq/preisalarm/">Preisalarm </a>
                    <a href="/faq/marktupdate/">Marktupdate</a>
                  </div>
                </div>
                <div className={styles.linkColumn}>
                  <div className={styles.linkGroup}>
                    <h3 className={styles.subHeading}>Verkaufen</h3>
                    <a href="/altmetall-kaufen/">Altmetallpreis anfragen </a>
                    <a href="/altmetall-ankauf/">Kleine Mengen verkaufen</a>
                  </div>
                  <div className={styles.linkGroup}>
                    <h3 className={styles.subHeading}>Altmetall verkaufen</h3>
                    <a href="/altmetall-ankauf/">Altmetall Ankauf </a>
                    <a href="/altmetall-ankauf/aluminium/">Aluminium </a>
                    <a href="/altmetall-ankauf/blei/">Blei </a>
                    <a href="/altmetall-ankauf/edelstahl/">Edelstahl </a>
                    <a href="/altmetall-ankauf/eisen-und-stahl/">
                      Eisen und Stahl
                    </a>
                    <a href="/altmetall-ankauf/elektronik/">Elektroschrott </a>
                    <a href="/altmetall-ankauf/hartmetall-hss/">
                      Hartmetall und HSS
                    </a>
                    <a href="/altmetall-ankauf/kupfer-kabel/">Kabel </a>
                    <a href="/altmetall-ankauf/kupfer/">Kupfer </a>
                    <a href="/altmetall-ankauf/messing/">Messing </a>
                    <a href="/altmetall-ankauf/legierungen-cu-ni/">
                      Rotguss/Legierunge
                    </a>
                    <a href="/altmetall-ankauf/zink/">Zink </a>
                    <a href="/altmetall-ankauf/zinn/">Zinn</a>
                  </div>
                </div>
                <div className={styles.linkColumn}>
                  <div className={styles.linkGroup}>
                    <h3 className={styles.subHeading}>Kaufen</h3>
                    <a href="/altmetall-kaufen/">Altmetall kaufen </a>
                    <a href="/altmetall-kaufen/partner-werden/">
                      Schrott24 Partner werden
                    </a>
                    <a href="/altmetall-kaufen/partner/partnerwebshop/">
                      Ihr Webshop
                    </a>
                  </div>
                  <div className={styles.linkGroup}>
                    <h3 className={styles.subHeading}>Kunden</h3>
                    <a href="/pro">Industrie </a>
                    <a href="/altmetall-ankauf">Gewerbe/Handwerk </a>
                    <a href="/altmetall-kaufen">
                      Endverbraucher (Schmelzen, Hütten, etc.)
                    </a>
                    <a href="/altmetall-kaufen/partner-werden">
                      Altmetallhändler
                    </a>
                    <a href="/altmetall-ankauf">Privat </a> 
                  </div>
                  <div className={styles.linkGroup}>
                    <h3 className={styles.subHeading}>Impressum</h3>
                    <a href="/faq/impressum/">Impressium </a>
                    <a href="/faq/disclaimer/">Disclaimer </a>
                    <a href="/faq/agb/">AGB</a>
                  </div>
                </div>
              </div>
              <div
                className={`${styles.linkContainer} ${styles.visibleDesktopTablet}`}
              >
                <div className={styles.linkColumn}>
                  <div className={styles.linkGroup}>
                    <h3 className={styles.subHeading}>Schrottpreise</h3>
                    <a href="/schrottpreise/">Schrottpreise </a>
                    <a href="/hartmetallpreis/">Hartmetallpreis </a>
                    <a href="/edelstahlpreis/">Edelstahlpreis </a>
                    <a href="/zinkpreis/">Zinkpreis</a>
                    <a href="/messingpreise/">Messingpreis</a>
                    <a href="/kabelpreise/">Kabelpreis</a>
                    <a href="/aluminiumpreis/">Aluminiumpreis</a>
                    <a href="/elektroschrottpreise/">E-Schrottpreis</a>
                    <a href="/bleipreise/">Bleipreis</a>
                    <a href="/legierungenpreis/">Legierungenpreis</a>
                    <a href="/zinnpreis/">Zinnpreis</a>
                    <a href="/kupferpreis/">Kupferpreis</a>
                    <a href="/eisenpreise/">Eisenpreis</a>
                    <a href="/stahlpreise/">Stahlpreis</a>
                    <a href="/batteriepreise/">Batteriepreis</a>
                  </div>
                  <div className={styles.linkGroup}>
                    <h3 className={styles.subHeading}>Standorte Österreich</h3>
                    <a href="/standorte/schrottplatz-dornbirn/">
                      Raum Dornbirn
                    </a>
                    <a href="/standorte/schrottplatz-graz/">Raum Graz </a>
                    <a href="/standorte/schrottplatz-kaernten/">
                      Raum Klagenfurt/Althofen
                    </a>
                    <a href="/standorte/schrottplatz-linz/">Raum Linz </a>
                    <a href="/standorte/schrottplatz-salzburg/">
                      Raum Salzburg Stadt
                    </a>
                    <a href="/standorte/schrottplatz-wien/">Raum Wien</a>
                  </div>
                </div>
                <div className={styles.linkColumn}>
                  <div className={styles.linkGroup}>
                    <h3 className={styles.subHeading}>Standorte Deutschland</h3>
                    <a href="/standorte/schrottplatz-bayern/">Bayern </a>
                    <a href="/standorte/schrottplatz-donauwoerth/">
                      Donauwörth
                    </a>
                    <a href="/standorte/schrottplatz-eiselfing/">Eiselfing </a>
                    <a href="/standorte/schrottplatz-kiefersfelden/">
                      Kiefersfelden
                    </a>
                    <a href="/standorte/schrottplatz-memmingen/">Memmingen </a>
                    <a href="/standorte/schrottplatz-muenchen/">München </a>
                    <a href="/standorte/schrottplatz-nuernberg/">Nürnberg </a>
                    <a href="/standorte/schrottplatz-regensburg/">Regensburg</a>
                    <a href="/schrottplatz-baden-wuerttemberg/">
                      Baden-Württemberg
                    </a>
                    <a href="/standorte/schrottplatz-karlsruhe/">Karlsruhe </a>
                    <a href="/standorte/schrottplatz-mannheim/">Mannheim </a>
                    <a href="/schrottplatz-stuttgart/">Stuttgart </a>
                    <a href="/standorte/schrottplatz-neu-ulm/">Ulm </a>
                    <a href="/schrottplatz-berlin/">Berlin </a>
                    <a href="/schrottplatz-bremen/">Bremen </a>
                    <a href="/schrottplatz-hamburg-online-altmetall-verkaufen/">
                      Hamburg
                    </a>
                    <a href="/schrottplatz-hessen/">Hessen </a>
                    <a href="/schrottplatz-frankfurt/">Frankfurt am Main </a>
                    <a href="/standorte/schrottplatz-mecklenburg-vorpommern/">
                      Mecklenburg-Vorpommern
                    </a>
                    <a href="/standorte/schrottplatz-friedland/">Friedland </a>
                    <a href="/standorte/schrottplatz-brandenburg/">
                      Neubrandenburg
                    </a>
                    <a href="/standorte/schrottplatz-rostock/">Rostock </a>
                    <a href="/standorte/schrottplatz-schwerin/">Schwerin </a>
                    <a href="/standorte/schrottplatz-wismar/">Wismar</a>
                  </div>
                </div>
                <div className={styles.linkColumn}>
                  <div className={styles.linkGroup}>
                    <h3 className={styles.subHeading}>&nbsp;</h3>
                    <a href="/standorte/schrottplatz-niedersachsen/">
                      Niedersachsen
                    </a>
                    <a href="/standorte/schrottplatz-braunschweig/">
                      Braunschweig
                    </a>
                    <a href="/standorte/schrottplatz-hannover/">Hannover </a>
                    <a href="/standorte/schrottplatz-kettenkamp/">Kettenkamp</a>
                    <a href="/standorte/schrottplatz-osnabrueck/">Osnabrück </a>
                    <a href="/standorte/schrottplatz-nordrhein-westfalen/">
                      Nordrhein-Westfalen
                    </a>
                    <a href="/standorte/schrottplatz-hagen/">Hagen </a>
                    <a href="/standorte/schrottplatz-hamm/">Hamm </a>
                    <a href="/standorte/schrottplatz-luenen/">Lünen </a>
                    <a href="/standorte/schrottplatz-guetersloh/">Gütersloh </a>
                    <a href="/standorte/schrottplatz-saarland/">Saarland </a>
                    <a href="/standorte/schrottplatz-homburg/">Homburg </a>
                    <a href="/standorte/schrottplatz-sachsen/">Sachsen </a>
                    <a href="/standorte/schrottplatz-chemnitz/">Chemnitz </a>
                    <a href="/standorte/schrottplatz-dresden/">Dresden </a>
                    <a href="/standorte/schrottplatz-leipzig/">Leipzig </a>
                    <a href="/standorte/schrottplatz-sachsen-anhalt/">
                      Sachsen-Anhalt
                    </a>
                    <a href="/standorte/schrottplatz-halle/">Halle </a>
                    <a href="/standorte/schrottplatz-schleswig-holstein/">
                      Schleswig-Holstein
                    </a>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={`${styles.linkContainer} ${styles.visiblePhone}`}>
                <div className={styles.linkGroup}>
                  <h3 className={styles.subHeading}>Unternehmen</h3>
                  <a href="/">Homepage </a>
                  <a href="/ueber-uns/">Über uns</a>
                </div>
                <div className={styles.linkGroup}>
                  <h3 className={styles.subHeading}>Altmetall kaufen</h3>
                  <a href="/altmetall-kaufen/">Verkaufen</a>
                </div>
                <div className={styles.linkGroup}>
                  <h3 className={styles.subHeading}>Newsletter</h3>
                  <a href="/preisalarm/">Preisalarm </a>
                  <a href="/marktupdate/">Marktupdate</a>
                </div>
                <div className={styles.linkGroup}>
                  <h3 className={styles.subHeading}>Schrottpreise</h3>
                  <a href="/pro/">Schrott24 Pro </a>
                  <a href="/schrottpreise/">Schrottpreise </a>
                  <a href="/aluminiumpreis/">Aluminiumpreis</a>
                  <a href="/bleipreise/">Bleipreis</a>
                  <a href="/edelstahlpreis/">Edelstahlpreis </a>
                  <a href="/eisenpreise/">Eisenpreis</a>
                  <a href="/stahlpreise/">Stahlpreis</a>
                  <a href="/elektroschrottpreise/">Elektroschrottpreis</a>
                  <a href="/hartmetallpreis/">Hartmetallpreis </a>
                  <a href="/kabelpreise/">Kabelpreis</a>
                  <a href="/kupferpreis/">Kupferpreis</a>
                  <a href="/messingpreise/">Messingpreis</a>
                  <a href="/zinkpreis/">Zinkpreis</a>
                  <a href="/zinnpreis/">Zinnpreis</a>
                </div>
                <div className={styles.linkGroup}>
                  <h3 className={styles.subHeading}>Impressum</h3>
                  <a href="/impressum/">Impressium </a>
                  <a href="/disclaimer/">Disclaimer </a>
                  <a href="/agb/">AGB</a>
                </div>
              </div>
              <div
                className={`${styles.linkContainer} ${styles.withBottomBorder} ${styles.visibleDesktopTablet}`}
              >
                <div className={styles.linkColumn}>
                  <div className={styles.linkGroup}>
                    <h3 className={styles.subHeading}>Unternehmen</h3>
                    <a href="/">Homepage </a>
                    <a href="/ueber-uns/">Über uns</a>
                  </div>
                  <div className={styles.linkGroup}>
                    <h3 className={styles.subHeading}>Altmetall kaufen</h3>
                    <a href="/altmetall-kaufen/">Verkaufen</a>
                  </div>
                </div>
                <div className={styles.linkColumn}>
                  <div className={styles.linkGroup}>
                    <h3 className={styles.subHeading}>Newsletter</h3>
                    <a href="/preisalarm/">Preisalarm </a>
                    <a href="/marktupdate/">Marktupdate</a>
                  </div>
                </div>
                <div className={styles.linkColumn}>
                  <div className={styles.linkGroup}>
                    <h3 className={styles.subHeading}>Impressum</h3>
                    <a href="/impressum/">Impressium </a>
                    <a href="/disclaimer/">Disclaimer </a>
                    <a href="/agb/">AGB</a>
                  </div>
                </div>
              </div>
              <div
                className={`${styles.linkContainer} ${styles.visibleDesktopTablet}`}
              >
                <div className={styles.linkColumn}>
                  <div className={styles.linkGroup}>
                    <h3 className={styles.subHeading}>Altmetall verkaufen</h3>
                    <a href="/pro/">Schrott24 Pro </a>
                    <a href="/schrottpreise/">Schrottpreise </a>
                    <a href="/aluminiumpreis/">Aluminiumpreis</a>
                    <a href="/bleipreise/">Bleipreis</a>
                    <a href="/edelstahlpreis/">Edelstahlpreis</a>
                  </div>
                </div>
                <div className={styles.linkColumn}>
                  <div className={styles.linkGroup}>
                    <h3 className={styles.subHeading}>&nbsp;</h3>
                    <a href="/eisenpreise/">Eisenpreis</a>
                    <a href="/stahlpreise/">Stahlpreis</a>
                    <a href="/elektroschrottpreise/">Elektroschrottpreis</a>
                    <a href="/hartmetallpreis/">Hartmetallpreis </a>
                    <a href="/kabelpreise/">Kabelpreis</a>
                  </div>
                </div>
                <div className={styles.linkColumn}>
                  <div className={styles.linkGroup}>
                    <h3 className={styles.subHeading}>&nbsp;</h3>
                    <a href="/kupferpreis/">Kupferpreis</a>
                    <a href="/messingpreise/">Messingpreis</a>
                    <a href="/zinkpreis/">Zinkpreis</a>
                    <a href="/zinnpreis/">Zinnpreis</a>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer domain={domain} />
      <GroupLegal />
    </I18nextProvider>
  );
};

export default SiteMapPage;
